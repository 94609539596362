<template>
    <onboarding-layout
        class="mt-5"
        :header="false"
    >
        <img
            src="@/assets/images/global/sorryidentification.svg"
            width="200px"
            alt="Magnifying Glass with ID"
        >
        <h5 class="fw-light">
            {{ $t('pages.shared.unableToVerifyIdentity.title') }}
        </h5>
        <p class="text-muted">
            {{ $t('pages.shared.unableToVerifyIdentity.subTitle') }}
        </p>
        <div class="d-grid">
            <a
                class="btn btn-secondary mt-3"
                @click="onclick"
            >{{ $t('pages.shared.unableToVerifyIdentity.cta') }}</a>
        </div>
    </onboarding-layout>
</template>

<script>
    // TODO: explore condensing this file and Sorry.vue into one component
    import OnboardingLayout from '@/layouts/Onboarding'
    import { logger } from '@/utils/logger'
    // import { verifyApplicant } from '@/services/idology'
    // import { appSessionStorage, localStorageKey } from '@/utils/storage'

    export default {
        components: {
            'onboarding-layout': OnboardingLayout,
        },
        mounted: function () {
            this.$logEvent('view_identity_unable_to_verify')
        },
        methods: {
            onclick: async function () {
                // For some reason, the 'go(-1)' ignores the difference between .push and .replace()
                // const verifyApplicantResponse = await verifyApplicant()
                // logger.info(`verifyApplicantResponse: ${JSON.stringify(verifyApplicantResponse.data)}`)
                // const payload = verifyApplicantResponse.data.payload
                // appSessionStorage.setItem(localStorageKey.identityQA, JSON.stringify(payload))

                logger.info('Moving backwards one page in the history...')
                await this.$router.go(-1)
            },
        },
    }
</script>
